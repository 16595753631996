/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, useInView, AnimatePresence, cubicBezier, useAnimate, stagger } from 'framer-motion';

import { useRef, useState, useEffect, useContext } from 'react';
import { useInViewScroll, MotionImage, useSanitizedTranslation, PromoBlock, XumoButton as Button, StackedContent, TextBlock } from 'ui';
import { useMeasure, useWindowSize } from '@uidotdev/usehooks';
import { PageContext } from 'ui/contexts';
import { NewsBlock } from '.';
import classNames from 'classnames';
import Image from 'next/image';

function getElementTopPosition(elem) {
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top = box.top + scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return top;
}
export type HeroProps = {};

function wrapWordsWithSpan(htmlString) {
  // Parse the HTML string into a document object
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Recursive function to process each node
  function processNode(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      // Split text into words and wrap each with a span
      const wrappedText = node.textContent.replace(/([\w\p{P}']+)/gu, '<span>$1</span>');
      const fragment = document.createRange().createContextualFragment(wrappedText);
      node.parentNode.replaceChild(fragment, node);
    } else {
      // Process each child node
      Array.from(node.childNodes).forEach(processNode);
    }
  }

  // Start processing from the body element
  processNode(doc.body);

  // Return the modified HTML as a string
  return doc.body.innerHTML;
}

const PolygonOverlay = () => (
  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}>
    <polygon
      points="-200% 0, 52% 0, 52% 200%, 300% 200%, 300% 0, 52% 0, 52% 100%, -200% 100%, -200% 400%, 300% 400%"
      fill="rgba(255, 0, 0, 0.5)"
      stroke="red"
      strokeWidth="1"
    />
  </svg>
);

const MobileHero = ({}) => {
  const { t } = useSanitizedTranslation();

  const { headerHeight } = useContext(PageContext);
  const [heroRef, setHeroRef] = useState();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [wrapperRef, { width: initialWidth, height: initialHeight }] = useMeasure();
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (heroRef) {
      setOffsetTop(getElementTopPosition(heroRef));
    }
  }, [heroRef, setOffsetTop, windowHeight, windowWidth]);

  const scale = Math.max(((windowHeight || 1) - headerHeight) / ((initialHeight || 1) - headerHeight), (windowWidth || 1) / (initialWidth || 1));

  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100px', 'end 0vh']);

  const containerScale = useTransform(amt, [0, 0.4], [1.01, scale], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const heroPosition = useTransform(amt, [0, 0.3], [20, -offsetTop + 50 + headerHeight], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const imageY = useTransform(amt, [0, 1.0], ['0%', '-5%'], { ease: cubicBezier(0.35, 0, 0.44, 1) });
  const imageY2 = useTransform(amt, [0, 0.35], ['-5.75%', '4.5%'], { ease: cubicBezier(0.35, 0, 0.44, 1) });
  const imageY3 = useTransform(amt, [0, 1.0], ['0%', '-35%'], { ease: cubicBezier(0.35, 0, 0.44, 1), clamp: false });
  const imageOpacity = useTransform(amt, [0.5, 0.8], [1.0, 0], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const titleY = useTransform(amt, [0, 0.5], [0, -75]);

  const titleScale = useTransform(amt, [0, 0.5], [1, 0.9]);

  const [scope, animate] = useAnimate();
  const [bodyRef, bodyAnimate] = useAnimate();

  const bodyInView = useInView(bodyRef, { margin: '-30% 0% -30% 0%', once: true });

  useEffect(() => {
    animate(
      'span, strong',
      {
        y: [20, 0],
        opacity: [0, 1],
      },
      {
        ease: [0, 1, 1, 1],
        duration: 0.9,
        delay: stagger(0.075),
        deltaRest: 0.00001,
        deltaSpeed: 0.00001,
      },
    );
  }, []);

  useEffect(() => {
    if (bodyInView) {
      bodyAnimate(
        'span',
        {
          y: [40, 0],
          opacity: [0, 1],
        },
        {
          ease: [0, 1, 1, 1],
          duration: 0.9,
          delay: stagger(0.055),
          deltaRest: 0.00001,
          deltaSpeed: 0.00001,
        },
      );
    }
  }, [bodyInView]);

  const body = 'However you watch, <strong>Xumo makes it easy</strong> with our smart TVs, streaming devices and free entertainment.';
  const [formattedBody, setFormattedBody] = useState('');

  useEffect(() => {
    if (!formattedBody) {
      setFormattedBody(wrapWordsWithSpan(body));
    }
  }, [body]);

  const title = '<span>All</span> <span>you</span> <span>can</span> <strong>stream</strong>';

  return (
    <>
      <motion.section ref={ref} style={{ opacity: imageOpacity }} className="w-full bg-white" data-testid={'keyart-section'}>
        <div style={{ top: headerHeight }} className="wrapper">
          <motion.h1
            ref={scope}
            style={{ scale: titleScale }}
            className="type-title m-0 py-8 text-center 2xl:py-16 [&>*]:inline-block "
            data-i18n
            dangerouslySetInnerHTML={{ __html: t(title) }}
          />
          <motion.div className="content-wrapper-x relative top-0 aspect-[2560/1282] origin-top lg:top-2 2xl:top-0">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ ease: [0, 1, 1, 1], delay: 0.55, duration: 0.6 }}
              className="relative"
            >
              <motion.div key="hero" className="">
                <div
                  className="relative -top-full z-10 -mt-[30vh] h-[33vh] w-full"
                  style={{ clipPath: 'polygon(-500% 0, -500% 200%, 0% 200%, 0% 100%, 51.5% 100%, 51.5% 200%, 500% 200%, 500% 0%)' }}
                >
                  <motion.div
                    initial={{ opacity: 0, marginTop: 30 }}
                    animate={{ opacity: 1, marginTop: 0 }}
                    transition={{ delay: 0.75, ease: [0, 1, 1, 1], duration: 0.9, deltaRest: 0.00001, deltaSpeed: 0.00001 }}
                    className="absolute -left-[46%] top-[47%] z-10 h-full w-[192%] origin-top lg:top-[55%] xl:top-[57%] 2xl:top-[55%]"
                  >
                    <MotionImage
                      style={{ opacity: 1, rotate: 180 }}
                      src="/static/images/refresh/xumo-full-swirl-extended.png"
                      priority
                      fill
                      sizes="200vw"
                      alt=""
                      className="h-full w-full object-cover"
                    />
                  </motion.div>
                </div>
                <div
                  ref={wrapperRef}
                  className="relative flex aspect-[2560/1282] w-full origin-top items-center justify-center overflow-clip rounded-2xl bg-xumoIce"
                >
                  <MotionImage
                    ref={setHeroRef}
                    style={{ y: imageY, opacity: 1 }}
                    src="/static/images/refresh/xumo-hero-bg.jpg"
                    priority
                    fill
                    sizes="150vw"
                    alt="A picture of a family enjoying a Xumo Stream Box showing Penguin from HBO."
                    className="top-0 h-[105%] w-full object-cover object-bottom"
                  />
                </div>
              </motion.div>

              <motion.div
                style={{
                  opacity: 1,
                  filter: 'drop-shadow(10px 10px 3px rgba(0,0,0,0.15)) drop-shadow(1px 2px 2px rgba(0,0,0,0.75))',
                }}
                className="absolute left-[29%] top-[calc(15%+33vh)] z-10 aspect-[2560/1482] w-[42%] origin-top"
              >
                <MotionImage
                  style={{ opacity: 1 }}
                  src="/static/images/refresh/xumo-tv-frame.png"
                  priority
                  fill
                  sizes="100vw"
                  className="h-full w-full object-cover"
                />
                <div className="absolute left-[1%] top-[1.5%] aspect-video w-[98%]">
                  <MotionImage src="/static/images/refresh/penguin-streambox.png" priority fill sizes="100vw" alt="" className="h-full w-full object-cover" />
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>
    </>
  );
};

const DesktopHero = ({}) => {
  const { t } = useSanitizedTranslation();

  const { headerHeight } = useContext(PageContext);
  const [heroRef, setHeroRef] = useState();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [wrapperRef, { width: initialWidth, height: initialHeight }] = useMeasure();
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (heroRef) {
      setOffsetTop(getElementTopPosition(heroRef));
    }
  }, [heroRef, setOffsetTop, windowHeight, windowWidth]);

  const scale = Math.max(((windowHeight || 1) - headerHeight) / ((initialHeight || 1) - headerHeight), (windowWidth || 1) / (initialWidth || 1));

  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100px', 'end 0vh']);

  const containerScale = useTransform(amt, [0, 0.4], [1.01, scale], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const heroPosition = useTransform(amt, [0, 0.3], [20, -offsetTop + 50 + headerHeight], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const imageY = useTransform(amt, [0, 1.0], ['0%', '-5%'], { ease: cubicBezier(0.35, 0, 0.44, 1) });
  const imageY2 = useTransform(amt, [0, 0.35], ['-5.75%', '4.5%'], { ease: cubicBezier(0.35, 0, 0.44, 1) });
  const imageY3 = useTransform(amt, [0, 1.0], ['0%', '-35%'], { ease: cubicBezier(0.35, 0, 0.44, 1), clamp: false });
  const imageOpacity = useTransform(amt, [0.5, 0.8], [1.0, 0], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const titleY = useTransform(amt, [0, 0.5], [0, -75]);

  const titleScale = useTransform(amt, [0, 0.5], [1, 0.9]);

  const [scope, animate] = useAnimate();
  const [bodyRef, bodyAnimate] = useAnimate();

  const bodyInView = useInView(bodyRef, { margin: '-30% 0% -30% 0%', once: true });

  useEffect(() => {
    animate(
      'span, strong',
      {
        y: [20, 0],
        opacity: [0, 1],
      },
      {
        ease: [0, 1, 1, 1],
        duration: 0.9,
        delay: stagger(0.075),
        deltaRest: 0.00001,
        deltaSpeed: 0.00001,
      },
    );
  }, []);

  useEffect(() => {
    if (bodyInView) {
      bodyAnimate(
        'span',
        {
          y: [40, 0],
          opacity: [0, 1],
        },
        {
          ease: [0, 1, 1, 1],
          duration: 0.9,
          delay: stagger(0.055),
          deltaRest: 0.00001,
          deltaSpeed: 0.00001,
        },
      );
    }
  }, [bodyInView]);

  const body = 'However you watch, <strong>Xumo makes it easy</strong> with our smart TVs, streaming devices and free entertainment.';
  const [formattedBody, setFormattedBody] = useState('');

  useEffect(() => {
    if (!formattedBody) {
      setFormattedBody(wrapWordsWithSpan(body));
    }
  }, [body]);

  const title = '<span>All</span> <span>you</span> <span>can</span> <strong>stream</strong>';

  return (
    <>
      <motion.section ref={ref} style={{ opacity: imageOpacity }} className="w-full bg-white" data-testid={'keyart-section'}>
        <div style={{ top: headerHeight }} className="wrapper sticky h-0">
          <motion.h1
            ref={scope}
            style={{ y: titleY, scale: titleScale }}
            className="type-title m-0 py-8 text-center 2xl:py-16 [&>*]:inline-block "
            data-i18n
            dangerouslySetInnerHTML={{ __html: t(title) }}
          />
          <motion.div style={{ y: heroPosition }} className="content-wrapper-x relative top-0 aspect-[2560/1282] origin-top lg:top-2 2xl:top-0">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ ease: [0, 1, 1, 1], delay: 0.55, duration: 0.6 }}
              className="relative"
            >
              <motion.div key="hero" style={{ opacity: 1, scale: containerScale }} className="">
                <div
                  className="relative -top-full z-10 -mt-[30vh] h-[33vh] w-full"
                  style={{ clipPath: 'polygon(-500% 0, -500% 200%, 0% 200%, 0% 100%, 51.5% 100%, 51.5% 200%, 500% 200%, 500% 0%)' }}
                >
                  <motion.div
                    initial={{ opacity: 0, marginTop: 30 }}
                    animate={{ opacity: 1, marginTop: 0 }}
                    transition={{ delay: 0.75, ease: [0, 1, 1, 1], duration: 0.9, deltaRest: 0.00001, deltaSpeed: 0.00001 }}
                    style={{ y: imageY2 }}
                    className="absolute -left-[46%] top-[47%] z-10 h-full w-[192%] origin-top lg:top-[55%] xl:top-[57%] 2xl:top-[55%]"
                  >
                    <MotionImage
                      style={{ opacity: 1, rotate: 180 }}
                      src="/static/images/refresh/xumo-full-swirl-extended.png"
                      priority
                      fill
                      sizes="200vw"
                      className="h-full w-full object-cover"
                    />
                  </motion.div>
                </div>
                <div
                  ref={wrapperRef}
                  className="relative flex aspect-[2560/1282] w-full origin-top items-center justify-center overflow-clip rounded-2xl bg-xumoIce"
                >
                  <MotionImage
                    ref={setHeroRef}
                    style={{ y: imageY, opacity: 1 }}
                    src="/static/images/refresh/xumo-hero-bg.jpg"
                    priority
                    fill
                    sizes="150vw"
                    className="top-0 h-[105%] w-full object-cover object-bottom"
                  />
                </div>
              </motion.div>

              <motion.div
                style={{
                  opacity: 1,
                  scale: containerScale,
                  y: imageY3,
                  filter: 'drop-shadow(10px 10px 3px rgba(0,0,0,0.15)) drop-shadow(1px 2px 2px rgba(0,0,0,0.75))',
                }}
                className="absolute left-[29%] top-[calc(15%+33vh)] z-10 aspect-[2560/1482] w-[42%] origin-top"
              >
                <MotionImage
                  style={{ opacity: 1 }}
                  src="/static/images/refresh/xumo-tv-frame.png"
                  priority
                  fill
                  sizes="100vw"
                  className="h-full w-full object-cover"
                />
                <div className="absolute left-[1%] top-[1.5%] aspect-video w-[98%]">
                  <MotionImage src="/static/images/refresh/streambox-squidgame.jpg" priority fill sizes="100vw" className="h-full w-full object-cover" />
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>

        <div className="h-[250vh]" />
      </motion.section>
      <div className="relative z-40 -mt-[50vh] h-[400px] w-full" style={{ background: 'linear-gradient(to top, white 0%, rgba(255,255,255,0) 100%)' }} />
    </>
  );
};

export const Hero = ({}) => {
  const { t } = useSanitizedTranslation();
  const { buckets } = useContext(PageContext);

  const { width: windowWidth } = useWindowSize();

  const [wasMobile, setWasMobile] = useState(typeof window === 'undefined');

  useEffect(() => {
    if (!wasMobile && windowWidth !== null && windowWidth <= 768) {
      setWasMobile(true);
    }
  }, [wasMobile, windowWidth]);

  const [bodyRef, bodyAnimate] = useAnimate();

  const bodyInView = useInView(bodyRef, { margin: '-30% 0% -30% 0%', once: true });

  useEffect(() => {
    if (bodyInView) {
      bodyAnimate(
        'span',
        {
          y: [40, 0],
          opacity: [0, 1],
        },
        {
          ease: [0, 1, 1, 1],
          duration: 0.9,
          delay: stagger(0.055),
          deltaRest: 0.00001,
          deltaSpeed: 0.00001,
        },
      );
    }
  }, [bodyInView]);

  const body = 'However you watch, <strong>Xumo makes it easy</strong> with our smart TVs, streaming devices and free entertainment.';
  const [formattedBody, setFormattedBody] = useState('');

  useEffect(() => {
    if (!formattedBody) {
      setFormattedBody(wrapWordsWithSpan(body));
    }
  }, [body]);

  const title = '<span>All</span> <span>you</span> <span>can</span> <strong>stream</strong>';

  return (
    <>
      {wasMobile ? <MobileHero /> : <DesktopHero />}
      <div className="relative z-40 w-full bg-white">
        <motion.div
          ref={bodyRef}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, margin: '-30% 0% -30% 0%' }}
          className="wrapper type-heading-alt pb-8 pt-8 text-center  md:pb-16 2xl:pb-32 [&>span]:inline-block [&>strong>span]:inline-block"
          data-i18n
          dangerouslySetInnerHTML={{ __html: t(formattedBody) }}
        />
      </div>


      { buckets[0] === 1 && <PromoTwo /> }

      <TextBlock
        bg="bg-[#f5f7f8] rounded-t-3xl"
        content={[
          { heading: '<strong>A better way</strong> to stream' },
          { body: 'Xumo’s signature products offer a simplified streaming experience made for the way you watch.' },
        ]}
      />

      <TwoColumn
        bg="bg-[#f5f7f8]"
        one
        content={[
          {
            image: {
              src: '/static/images/refresh/xumo-tv-logo-horiz.svg',
              alt: 'Xumo TV',
              width: 237,
              height: 41,
              renderedHeight: 30,
            },
          },
          { body: 'A smart TV experience designed to help you stream easy.' },
          {
            ctaRow: [
              {
                href: '/products/xumo-tv',
                label: 'Meet Xumo TV',
              },
            ],
          },
        ]}
      />

      <TwoColumn
        two
        flipped
        bg="bg-[#f5f7f8]"
        content={[
          {
            image: {
              src: '/static/images/refresh/xumo-stream-box-horiz.png',
              alt: 'Xumo Stream Box',
              width: 470,
              height: 3138,
              renderedHeight: 30,
            },
          },
          { body: 'A streaming device designed to help you get more from your TV.' },
          {
            ctaRow: [
              {
                href: '/products/xumo-stream-box',
                label: 'Meet Xumo Stream Box',
              },
            ],
          },
        ]}
      />

      <TileScroller
        bg="bg-[#f5f7f8] rounded-b-3xl"
        heading="All the top apps"
        body="Pre-loaded with 250+ must-watch apps — just sign into your subscriptions or explore tons of free entertainment to get started."
      />

      { /* Adobe AB test */ }  
      <PromoBlock />
      { buckets[0] === 0 && <PromoTwo />}
      <TwoColumnAlt />
    </>
  );
};

const PromoTwo = ({}) => {
  const { t } = useSanitizedTranslation();
  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const scrollY = useTransform(amt, [0, 1], [-75, 75]);
  const scrollY2 = useTransform(amt, [0, 1], [-200, 50]);

  return (
    <motion.section ref={ref} className="relative z-40 flex w-full justify-center bg-white py-8" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`relative overflow-clip rounded-3xl bg-xumoIce md:pt-8`}>
          <div className="content-wrapper">
            <StackedContent full noOrphans center content={[{ heading: '<strong>Free entertainment</strong><br/>on any screen' }]} />
          </div>
          <div className="relative left-[0%] w-[100%] max-md:py-12">
            <motion.div
              style={{ opacity: 1, y: scrollY2 }}
              className="absolute -left-[10%] top-[20%] h-[90%] w-[112%] origin-top overflow-visible md:h-[98%] lg:-left-[12%] lg:-top-[5%] lg:h-[117%]"
            >
              <MotionImage
                style={{ opacity: 1 }}
                src="/static/images/refresh/xumo-play-swirl.png"
                priority
                fill
                sizes="200vw"
                className="h-full w-full object-cover"
              />
            </motion.div>

            <motion.div
              style={{
                opacity: 1,
                willChange: 'filter',
                filter: 'drop-shadow(10px 10px 3px rgba(0,0,0,0.15)) drop-shadow(1px 2px 2px rgba(0,0,0,0.75))',
              }}
              className="relative left-[10%] z-10 aspect-[2560/1482] w-[80%] lg:left-[15%] lg:w-[70%]"
            >
              <MotionImage
                style={{ opacity: 1 }}
                src="/static/images/refresh/xumo-tv-frame.png"
                priority
                fill
                sizes="100vw"
                className="h-full w-full object-cover"
              />
              <div className="absolute left-[1%] top-[1.5%] aspect-video w-[98%]">
                <MotionImage src="/static/images/refresh/xumo-play-ui.png" priority fill sizes="100vw" className="h-full w-full object-cover" />
              </div>
            </motion.div>

            <motion.div
              style={{ opacity: 1, y: scrollY2 }}
              className="absolute -left-[18%] top-[20%] z-10 h-[90%] w-[40%] origin-top md:-left-[11.8%] md:h-[98%] lg:-left-[12%] lg:-top-[5%] lg:h-[117%] lg:w-[36%] "
            >
              <MotionImage
                style={{ opacity: 1 }}
                src="/static/images/refresh/xumo-play-swirl-fg.png"
                priority
                fill
                sizes="200vw"
                className="h-full w-full object-cover"
              />
            </motion.div>
          </div>

          <div className="content-wrapper">
            <StackedContent
              full
              center
              noOrphans
              content={[
                {
                  image: {
                    src: '/static/images/refresh/xumo-play-logo.svg',
                    alt: 'Xumo Play',
                    width: 237,
                    height: 41,
                    renderedHeight: 30,
                  },
                },
                {
                  body: 'A free streaming service with 350+ channels and 15,000 titles on demand designed to watch anytime, on any device — no subscription or login needed.',
                },
                {
                  ctaRow: [
                    {
                      href: 'https://play.xumo.com',
                      label: 'Watch now',
                      target: '_blank',
                    },
                  ],
                },
                {
                  ctaRow: [
                    {
                      image: {
                        src: '/static/images/refresh/google-play.png',
                        alt: '',
                        height: 160 * (60 / 160),
                        width: 540 * (60 / 160),
                      },

                      href: 'https://play.google.com/store/apps/details?id=com.xumo.xumo',
                      label: 'Download on Google Play',
                      target: '_blank',
                    },
                    {
                      image: {
                        src: '/static/images/refresh/apple-app-store.png',
                        alt: '',
                        height: 160 * (60 / 160),
                        width: 480 * (60 / 160),
                      },
                      href: 'https://apps.apple.com/us/app/xumo-play-stream-tv-movies/id1068337978',
                      label: 'Download on Apple App Store',
                      target: '_blank',
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export const TwoColumn = ({ one, two, content = 'FPO', bg = 'bg-[#f5f7f8]', flipped = false, label = '' }) => {
  const { t } = useSanitizedTranslation();

  return (
    <motion.section className="relative flex w-full items-center bg-white" style={{ zIndex: one ? 5 : '' }} data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`${bg} items-center py-8 xl:py-16`}>
          <div className={`relative flex max-md:flex-col-reverse ${flipped ? 'flex-row-reverse' : 'flex-row'} justify-center`}>
            <div className="content-wrapper flex basis-1/2 max-md:pt-16">
              <StackedContent center full noOrphans content={content} />
            </div>
            {one && (
              <div className="relative flex basis-1/2 items-center">
                <div className="aspect-[1280/741] w-full" />
                <div
                  style={{
                    willChange: 'filter',
                    filter: 'drop-shadow(10px 10px 3px rgba(0,0,0,0.15)) drop-shadow(1px 2px 2px rgba(0,0,0,0.75))',
                  }}
                  className="absolute aspect-[1280/741] w-[90%] max-md:left-[5%] md:w-[130%]"
                >
                  <MotionImage src={'/static/images/refresh/xumo-tv-promo.png'} alt="" fill className="object-cover" sizes="100vw" />
                </div>
              </div>
            )}
            {two && (
              <div className="relative flex basis-1/2">
                <div className="aspect-[992/750] w-full" />
                <div className="absolute aspect-[992/750] h-full w-full">
                  <MotionImage src={'/static/images/refresh/xumo-sb-promo.png'} alt="" fill className="object-cover" sizes="100vw" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export const TwoColumnAlt = ({ content = 'FPO', bg = 'bg-[#f5f7f8]', flipped = false, label = '' }) => {
  const { t } = useSanitizedTranslation();

  return (
    <motion.section className="sw-full relative z-40 flex justify-center bg-white" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`relative flex py-16 max-md:flex-col-reverse xl:py-24 [&>div]:flex [&>div]:basis-1/2`}>
          <div className="content-wrapper-x">
            <StackedContent
              mobileCenter
              content={[
                { headingAlt: 'Take your business<br/>to the next level' },
                { body: 'With innovative approaches to optimizing your business, Xumo helps reach the next generation of streaming audiences.' },
                {
                  ctaRow: [
                    {
                      href: '/enterprise',
                      label: 'Xumo Enterprise',
                    },
                    {
                      href: '/advertising',
                      label: 'Xumo Advertising',
                    },
                  ],
                },
              ]}
            />
          </div>
          <div className="relative my-8 aspect-[1522/807] w-full">
            <Image src={'https://xumo-cms.directus.app/assets/aca8d70a-342f-42c9-84a9-e676a35f492e'} fill alt="" className="h-full w-full object-contain" />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export const TileScroller = ({ bg, heading, body }) => {
  const { ref, progress } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);

  const scrollX = useTransform(progress, [0, 1], ['-15vw', '15vw']);
  const scrollXAlt = useTransform(progress, [0, 1], ['15vw', '-15vw']);

  const variants = {
    visible: {
      transition: {
        delayChildren: 0,
        staggerChildren: 0.02,
        restDelta: 0.0001,
        restSpeed: 0.0001,
      },
    },
  };

  const childVariants = {
    initial: {
      y: 100,
      opacity: 0.0,
    },
    visible: {
      y: 0,
      opacity: [0, 1],
      transition: {
        ease: [0, 1, 1, 1],
        duration: 1.5,
        restDelta: 0.00001,
        restSpeed: 0.00001,
      },
    },
  };

  const { t } = useSanitizedTranslation();

  const imgs = [
    '/static/images/refresh/tiles/homepage/amc+.png', // 0
    '/static/images/refresh/tiles/homepage/apple tv+.png', // 1
    '/static/images/refresh/tiles/homepage/BET+.png', // 2
    '/static/images/refresh/tiles/homepage/britbox.png', // 3
    '/static/images/refresh/tiles/homepage/Custiosity stream.png', // 4
    '/static/images/refresh/tiles/homepage/DAZN.png',
    '/static/images/refresh/tiles/homepage/Disney+.png', //6
    '/static/images/refresh/tiles/homepage/ESPN+.png',
    '/static/images/refresh/tiles/homepage/youtube.png',
    '/static/images/refresh/tiles/homepage/hulu.png',
    '/static/images/refresh/tiles/homepage/max.png', // 10
    '/static/images/refresh/tiles/homepage/Netflix.png', // 11
    '/static/images/refresh/tiles/homepage/pandora.png',
    '/static/images/refresh/tiles/homepage/Paramount+.png',
    '/static/images/refresh/tiles/homepage/peacock.png',
    '/static/images/refresh/tiles/homepage/pluto tv.png', // 15
    '/static/images/refresh/tiles/homepage/prime videp.png',
    '/static/images/refresh/tiles/homepage/sling.png',
    '/static/images/refresh/tiles/homepage/Spotify.png',
    '/static/images/refresh/tiles/homepage/STARZ.png',
    '/static/images/refresh/tiles/homepage/tubi.png', // 20
    '/static/images/refresh/tiles/homepage/ViX.png',
    '/static/images/refresh/tiles/homepage/xumo PLAY.png',
    '/static/images/refresh/tiles/homepage/Youtube TV.png',
  ];

  const tiles = [
    [11, 16, 23, 6, 2, 18],
    [14, 22, 9, 10, 3, 5],
    [20, 1, 7, 13, 12],
  ];

  return (
    <motion.section ref={ref} className="relative z-40 flex w-full justify-center bg-white" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`${bg} relative space-y-16 py-16 md:pt-24`}>
          <StackedContent full center content={[{ heading }, { body }]} />
          <div className="relative">
            <motion.div
              initial="initial"
              whileInView="visible"
              variants={variants}
              viewport={{ once: true, margin: '-20% 0% -20% 0%' }}
              className="absolute left-0 w-screen space-y-2 xl:space-y-4"
            >
              <motion.div style={{ x: scrollX }} className="relative flex space-x-2 xl:space-x-4">
                <div className="invisible inline-block aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
                <div className="space absolute -left-2 flex -translate-x-full space-x-2 xl:-left-4 xl:space-x-4">
                  {tiles[0].map((item, index) => (
                    <motion.div
                      key={index + imgs[item]}
                      variants={childVariants}
                      className="relative inline-block aspect-[16/7] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                    >
                      <MotionImage key={'img' + index + imgs[item]} src={imgs[item]} alt="" fill className="object-cover" sizes="40vw" />
                    </motion.div>
                  ))}
                </div>
                <motion.div className="absolute left-0 flex flex-row space-x-2 xl:space-x-4">
                  {tiles[0].map((item, index) => (
                    <motion.div
                      key={'alt' + index + imgs[item]}
                      variants={childVariants}
                      className="relative inline-block aspect-[16/7] w-[45vw] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                    >
                      <MotionImage key={'img' + index + imgs[item]} src={imgs[item]} alt="" fill className="object-cover" sizes="40vw" />
                    </motion.div>
                  ))}
                </motion.div>
              </motion.div>

              <motion.div style={{ x: scrollXAlt }} className="relative flex space-x-2 xl:space-x-4">
                <div className="invisible inline-block aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
                <div className="space absolute -left-2 flex -translate-x-full space-x-2 xl:-left-4 xl:space-x-4">
                  {tiles[1].map((item, index) => (
                    <motion.div
                      key={index + imgs[item]}
                      variants={childVariants}
                      className="relative inline-block aspect-[16/7] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                    >
                      <MotionImage key={'img' + index + imgs[item]} src={imgs[item]} alt="" fill className="object-cover" sizes="40vw" />
                    </motion.div>
                  ))}
                </div>
                <motion.div className="absolute left-0 flex flex-row space-x-2 xl:space-x-4">
                  {tiles[1].map((item, index) => (
                    <motion.div
                      key={'alt' + index + imgs[item]}
                      variants={childVariants}
                      className="relative inline-block aspect-[16/7] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                    >
                      <MotionImage key={'img' + index + imgs[item]} src={imgs[item]} alt="" fill className="object-cover" sizes="40vw" />
                    </motion.div>
                  ))}
                </motion.div>
              </motion.div>

              <motion.div style={{ x: scrollX }} className="relative flex space-x-2 xl:space-x-4">
                <div className="invisible inline-block aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
                <div className="space absolute -left-2 flex -translate-x-full space-x-2 xl:-left-4 xl:space-x-4">
                  {tiles[2].map((item, index) => (
                    <motion.div
                      key={index + imgs[item]}
                      variants={childVariants}
                      className="relative inline-block aspect-[16/7] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                    >
                      <MotionImage key={'img' + index + imgs[item]} src={imgs[item]} alt="" fill className="object-cover" sizes="40vw" />
                    </motion.div>
                  ))}
                </div>
                <motion.div className="absolute left-0 flex flex-row space-x-2 xl:space-x-4">
                  {tiles[2].map((item, index) => (
                    <motion.div
                      key={'alt' + index + imgs[item]}
                      variants={childVariants}
                      className="relative inline-block aspect-[16/7] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                    >
                      <MotionImage key={'img' + index + imgs[item]} src={imgs[item]} alt="" fill className="object-cover" sizes="40vw" />
                    </motion.div>
                  ))}
                </motion.div>
              </motion.div>
            </motion.div>
            <div className="invisible aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
            <div className="invisible aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
            <div className="invisible aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
          </div>
        </div>
      </div>
    </motion.section>
  );
};
