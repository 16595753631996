import { createContext, useContext, useEffect, useState } from 'react';
import { Website } from '../types';
import { useMeasure } from '@uidotdev/usehooks';

// Function to generate buckets based on Adobe session ID
const generateBuckets = (bucketSizes: number[]) => {
  const sessionId = getAdobeSessionId();
  if (!sessionId) return bucketSizes.map(() => 0); // Return zeros if no session ID found

  const hash = simpleHash(sessionId);
  return bucketSizes.map((size, i) => ((hash + i) % size));
};

// A simple hash function for **deterministic** bucket assignment
const simpleHash = (str: string) => {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = ((hash << 5) + hash) ^ str.charCodeAt(i);
  }
  return hash >>> 0;
};

// Function to retrieve the Adobe session ID from cookies
const getAdobeSessionId = () => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=').map((part) => part.trim());
    if (name.startsWith('kndctr_') && name.endsWith('_AdobeOrg_identity')) {
      return decodeURIComponent(value);
    }
  }
  return null;
};

// Define the shape of the context type
export type PageContextType = {
  pageCtx: {
    host: Website.Enterprise | Website.Corporate | Website.Shopper | '';
    isCorporate: boolean;
  };
  setPageCtx: (ctx: {
    host: Website.Enterprise | Website.Corporate | Website.Shopper | '';
    isCorporate: boolean;
  }) => void;
  setTheme: (theme: any) => void;
  theme: any;
  headerHeight: number;
  buckets: number[];
};

export const PageContext = createContext<PageContextType>({
  pageCtx: { host: '', isCorporate: false },
  setPageCtx: () => {},
  theme: {},
  setTheme: () => {},
  headerHeight: 0,
  buckets: [],
});

export const PageProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [pageCtx, setPageCtx] = useState<PageContextType['pageCtx']>({
    host: Website.Shopper,
    isCorporate: false,
  });
  const [theme, setTheme] = useState<any>({});
  const [buckets, setBuckets] = useState<number[]>([]);

  const [headerRef, { height: headerHeight }] = useMeasure();

  useEffect(() => {
    // Define the bucket sizes for your A/B testing. This assumes 3 tests.
    // const [ testOne ] = useAbBuckets()
    // testOne == 0 (group A)
    // testOne == 1 (group B)

    const bucketSizes = [2, 2, 2];
    setBuckets(generateBuckets(bucketSizes));
  }, []);

  return (
    <PageContext.Provider
      value={{
        pageCtx,
        setPageCtx,
        theme,
        setTheme,
        headerHeight,
        buckets,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

// Custom hook to access A/B buckets from the context
export const useAbBuckets = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('useAbBuckets must be used within a PageProvider');
  }
  return context.buckets;
};
